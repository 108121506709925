.b-inline-search {
  position: relative;

  &__list {
    position: absolute;
    top: 40px;
    left: 0;
    height: 100%;
    max-height: 300px;
    width: 100%;
    background-color: var(--color-gray--90);
    border-radius: 0 0 6px 6px;
    padding: 12px 12px;
    list-style: none;
    border: 1px solid var(--border-color);
    z-index: 1;
    margin: 0;
    cursor: pointer;
  }
  &__icon{
    position: absolute;
    right: 12px;
    top: 12px;
    color: var(--bg-color--light);
  }
  &__input{
    padding-right: 40px;
  }
}
