.c-common-search {
  position: relative;

  &__list {
    position: absolute;
    top: 40px;
    left: 0;
    height: auto;
    max-height: 300px;
    overflow-y: auto;
    width: 100%;
    background-color: var(--color-gray--90);
    border-radius: 0 0 6px 6px;
    padding: 0;
    list-style: none;
    border: 1px solid var(--border-color);
    z-index: 1;
    margin: 0;
    cursor: pointer;
  }

  &__icon {
    position: absolute;
    right: 12px;
    top: 12px;
    color: var(--bg-color--light);
  }

  &__input {
    padding-right: 40px;
  }

  &__item {
    padding: 9px 12px;
    font-size: 16px;

    border-bottom: 1px solid var(--border-color);
  }
}
