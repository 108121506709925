@import "~primereact/resources/primereact.css";
@import "../generic/primereact-theme.generic";
@import "../generic/primereact-table.generic";
@import "../generic/primereact-input.generic";
@import "../generic/primereact-button.generic";
@import "../generic/primereact-multiselect.generic";
@import "../generic/primereact-dropdown.generic";
@import "../generic/primereact-checkbox.generic";
@import "../generic/primereact-calendar.generic";
@import "../generic/primereact-dialog.generic";
@import "../generic/primereact-tab.generic";
@import "../generic/filepond.generic";
//@import "~mmenu-light/src/mmenu-light";
@import "~bootstrap/scss/bootstrap-grid";
