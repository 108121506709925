.b-text {
  font-size: 16px;
  font-family: var(--font-family-primary);
  font-weight: 400;

  &--label {
    font-size: 12px;
    line-height: 12px;
    font-family: var(--font-family-secondary);
    color: var(--bg-color--light);
  }

  &--toast-title{
    font-size: 12px;
    font-family: var(--font-family-secondary);
    color: var(--bs-black);
    font-weight: 700;
  }
  &--toast{
    font-size: 12px;
    line-height: 12px;
    font-family: var(--font-family-secondary);
    color: var(--color-gray--30);
  }

  &--s{
    font-size: 14px;
    line-height: 14px;
    font-family: var(--font-family-secondary);
    color: var(--color-gray--30);
  }


  &--xl{
    font-size: 16px;
  }

}
