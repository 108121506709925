.p-float-label {
  @extend .mt-9;
}

.p-inputtext {
  &[readonly] {
    pointer-events: none;
    border: 0;
    box-shadow: none;
    outline: 0;

    &:focus{
      border: 0;
      box-shadow: none;
      outline: 0;
    }
  }
}
.p-inputtext, .p-password, .p-inputnumber{
  width: 100%;
}
