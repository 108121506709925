.b-btn{
  //this is a copy from primereact .p-button class only for extend purposes. If you change .p-button class, change this.
  color: #fff;
  background: #27afe8;
  border: 1px solid #27afe8;
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 6px;
  &:enabled:hover{
    background: #1baae7;
    color: #fff;
    border-color: #1baae7;
  }
}
