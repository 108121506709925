@import "../../styles/blocks/btn.blocks";

.c-file-pond-image-editor {
  position: relative;

  &__open-file {
    position: absolute;
    top: 25px;
    right: 25px;
    z-index: 1;
    background-color: var(--color-gray--90);
    padding: 7px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
}

.p-dialog {
  //span {
  //  font-family: Lato, Helvetica, sans-serif;
  //  font-weight: normal;
  //}

  .FIE_topbar-save-button {
    span {
      font-family: Lato, Helvetica, sans-serif;
      font-weight: 700;
    }

    @extend .b-btn
  }
}
