.p-column-filter-overlay {
  position: absolute;
}

.p-column-filter-row .p-column-filter-clear-button, .p-column-filter-row .p-column-filter-menu-button {
  display: none;
}

.p-datatable .p-datatable-thead > tr > th {
  min-width: 110px;
}

.p-tieredmenu {
  width: auto;
}

.p-datatable .p-datatable-tbody > tr.p-row-priority {
  background-color: var(--color-gray--90)
}

.p-datatable .p-datatable-tbody > tr.p-row-selected {
  background-color: var(--color-selected)
}

.p-row-disable-selection {
  .p-checkbox {
    display: none;
  }
}
.p-datatable.p-datatable--light .p-datatable-thead > tr > th{
  background: var(--color-white)
}
.p-datatable .p--column.p-highlight:not(.p--disabled):hover{
  background: #f6f9fc;
}
