.b-container {
  background-color: var(--bg-color--dark);
  background-image: url("../../../public/images/background.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: -32px;

  &--center {
    background-image: url("../../../public/images/logo-background.png");
    display: grid;
    justify-content: center;
    grid-template-columns: minmax(auto, 550px);
    height: 100vh;
    align-items: center;
    margin: 0;
    background-color: transparent;

  }
}
