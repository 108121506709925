.p-multiselect {
  height: 45px;
  width: 100%;
  .p-multiselect-label span {

    &:after {
      content: ", "
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

  }

  &.p-multiselect-read-only {
    pointer-events: none;
    border: 0;
    .p-multiselect-trigger{
      display: none;
    }
  }
}
