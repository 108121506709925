.p-dropdown {
  width: 100%;
  height: 45px;
  display: flex;
  &.p-dropdown-read-only {
    pointer-events: none;
    border: 0;
    .p-dropdown-trigger{
      display: none;
    }
  }
}
