.p-button{
  display: inline-flex;
  align-items:center;
  &--full{
    width: 100%;
  }
  &--s{
    height: 45px;
  }
}
