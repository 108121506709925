@import "../../styles/imports/bootstrap/breakpoints.imports";

.c-menu {
  position: relative;
  width: 100%;
  color: var(--primary-color-text);
  transition: width, left, right, 300ms;
  height: 100%;
  &__header {
    padding: 16px 20px;
    position: relative;
    display: flex;
    justify-content: center;

    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 86%;
      left: 7%;
      bottom: 0;
      height: 1px;
      background-color: var(--border-color--trans);
    }
  }

  &__logo{
    height: 50px;

    @include media-breakpoint-down("lg") {
      height: 50px;
    }
  }

  &__profile {
    position: relative;
    padding: 16px 0;

    .ps-menuitem-root.ps-submenu-root > .ps-menu-button:hover {
      background-color: transparent;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;

      width: 86%;
      left: 7%;
      bottom: 0;
      height: 1px;
      background-color: var(--border-color--trans);
    }
  }

  &__collapse {
    position: absolute;
    z-index: 99;
    left: calc(100% - 16px);
    border-radius: 50%;
    height: 32px;
    width: 32px;
    padding: 0;
    top: 26px;
    color: var(--bg-color--dark);
    cursor: pointer;
    font-size: 18px;
    border: 0;
    background-color: var(--color-white);
    transition: transform .25s ease-in-out;
    @include media-breakpoint-down("lg"){
      display: none
    }
  }

  a {
    color: var(--primary-color-text);
  }


  .ps-sidebar-root {
    min-height: 100vh;
    height: 100%;
    width: 100%;
    //background-color: var(--bg-color);
    background: var(--bg-color--dark);
    box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 14px;

    .ps-sidebar-container {
      background-color: transparent;
    }

    .ps-submenu-content {
      background-color: var(--bg-color--darker);

    }


    .ps-menu-button:hover {
      background-color: var(--color-secondary);
    }

  }

  .ps-active {
    background-color: var(--color-secondary);
  }

  &--collapsed {
    width: 80px;

    .c-menu__collapse {
      transform: rotate(180deg);
    }
  }
}
