//
//
@font-face {
  font-family: "Roboto";
  src: url("../../assets/fonts/Roboto/Roboto-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "RobotoSlab";
  src: url("../../assets/fonts/RobotoSlab/RobotoSlab.ttf") format("truetype");
}
//
//@font-face {
//  font-family: "Raleway";
//  src: url("../../assets/fonts/Raleway-Bold.ttf") format("truetype");
//  font-weight: bold;
//}
//
//@font-face {
//  font-family: "Raleway";
//  src: url("../../assets/fonts/Raleway-Black.ttf") format("truetype");
//  font-weight: 900;
//}
//
//@font-face {
//  font-family: "Montserrat";
//  src: url("../../assets/fonts/Montserrat-Regular.ttf") format("truetype");
//  font-weight: normal;
//}
//
//@font-face {
//  font-family: "Montserrat";
//  src: url("../../assets/fonts/Montserrat-Bold.ttf") format("truetype");
//  font-weight: bold;
//}
//
//@font-face {
//  font-family: "Montserrat";
//  src: url("../../assets/fonts/Montserrat-Black.ttf") format("truetype");
//  font-weight: 900;
//}
//
//
:root {
  --font-family-primary: 'RobotoSlab';
  --font-family-secondary: 'Roboto';
}
