
.c-detail-header {
  margin-bottom: 32px;
  border-bottom: 1px solid var(--border-color);

  &__title {
    border-bottom: 0;
    margin: 0;
  }

}
