@import "../../styles/imports/bootstrap/breakpoints.imports";

.c-responsive-structure {
  display: flex;

  &__icon {
    z-index: 99999;
    display: none;
    position: fixed;
    right: 10px;
    top: 10px;
    color: var(--color-white);
    cursor: pointer;
    font-size: 18px;
    border: 0;
    background-color: var(--bg-color--dark);
    border-radius: 50%;
    height: 32px;
    width: 32px;
    padding: 7px;
    @include media-breakpoint-down("lg") {
      display: block
    }
  }

  &__menu {
    //transition: width .5s ease-in-out;
    min-height: 100%;
    @include media-breakpoint-down("lg") {
      display: none
    }


  }

  &__content {
    position: relative;
    //transition: width .15s ease-in-out;
    @include media-breakpoint-down("lg") {
      width: 100% !important;
    }
  }

  &--collapse {
    .c-responsive-structure__icon {
      color: var(--bg-color--dark);
      background-color: var(--color-white);
    }

    .c-responsive-structure__menu {
      width: 100%;
      @include media-breakpoint-down("lg") {
        display: block
      }
    }

    .c-responsive-structure__content {
      display: none;
    }

  }

  &--full{
    .c-responsive-structure__menu {
      display: none;
    }
    .c-responsive-structure__icon {
      display: none;
    }

    .c-responsive-structure__content {
      display: block;
      width: 100%!important;
    }

  }
}
