.p-dialog {
  max-width: 90vw;
  width: auto;
  min-width: 300px;

  &.p-confirm-dialog {
    .p-dialog-header-icons {
      display: none;
    }
  }
}
