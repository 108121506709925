.filepond--wrapper {
  @extend .mt-9;

  .filepond--credits {
    display: none;
  }

  .filepond--root {
    margin-bottom: 0;

    &[data-disabled] {
      pointer-events: auto;

      .filepond--drop-label {
        pointer-events: auto !important;
        transform: none !important;

        &::after {
          visibility: hidden !important;
          opacity: 0 !important;

        }
      }

      .filepond--action-remove-item {
        display: none;
      }

      .filepond--panel, .filepond--drop-label.filepond--drop-label:after {
        //opacity: 0.4;
      }

      .filepond--file-info {
        transform: none !important;
      }

      .filepond--drop-label.filepond--drop-label label {
        opacity: 1;
        pointer-events: auto !important;
        display: inline-flex;
        align-items: center;
      }

      .filepond--panel {
        pointer-events: none
      }

    }

  }

  .filepond--drop-label {
    visibility: visible !important;
    opacity: 1 !important;
    transform: none !important;
  }

  .filepond--drop-label.filepond--drop-label {
    &:after {
      content: "Drag and drop";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: var(--text-color-secondary);

    }

    label {
      position: absolute;
      pointer-events: none;
      margin-top: -0.65rem;
      transition-property: all;
      transition-timing-function: ease;
      line-height: 1;
      top: -0.75rem;
      font-size: 12px;
      left: 0.75rem;
      color: var(--text-color-secondary);
      transition-duration: 0.2s;
      display: block;
      padding: 0;
      font-family: var(--font-family-secondary);
    }
  }

}
