.b-table-offer{
  border-collapse: collapse;
  td,th{
    margin: 0;
    padding: 10px 0;
  }
  th{
    border-top: 1px solid var(--color-gray--70);
  }
}
