.Toastify__close-button.Toastify__close-button--light {
  margin-top: 6px;
}
.Toastify__toast.Toastify__toast-theme--light{
  border-radius: 6px;
}

:root{
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #DC2626;
}
