.b-box {
  border-radius: 6px;
  background: var(--color-white);
  box-shadow:0 4px 10px rgba(0,0,0,.03),0 0 2px rgba(0,0,0,.06),0 2px 6px rgba(0,0,0,.12);
  @extend .p-7;
  &--sec{
    box-shadow:none;
    background: var(--color-gray--100);
  }


}
