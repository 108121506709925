:root{
  --bg-color:var(--text-color);
  --bg-color--dark: #12162E; // anterior 0B2347
  --bg-color--darker: #090b17; // anterior 0B2347
  --bg-color--darkest: #040409; // anterior 0B2347
  --bg-color--light: #6c6d77;
  --bg-global: #eff3f8;
  --border-color:#d3dbe3;
  --border-color--trans:  rgba(211, 219, 227, 0.175);
  --color-white: #FFFFFF;
  --color-gray--100:#f8f9fa;
  --color-gray--90:#f1f0ef;
  --color-gray--70: #d1d1d1;
  --color-gray--50: #adb5bd;
  --color-gray--30: #979797;
  --color-selected:rgb(41 199 111 / 15%);
  --color-secondary: #1395EC;

  --color-info: var(--toastify-color-info)
}
